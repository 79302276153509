<template>
  <block
    component-name="block-carousel-multiratio"
    ref="tpl_root"
    :px="false"
    body-class="flex flex-col items-stretch gap-3xl"
  >
    <template v-slot:default="blockData">
      <wrapper-reveal
        v-if="$attrs.data.body.text"
        tag="header"
        class="hidden w-full only-smartphone:block px-edge"
        reveal="fade-from-bottom"
      >
        <wrapper-split-text reveal="words" class="w-full">
          <div
            class="w-full text-skin-base text-heading-5/relaxed extdesktop:text-heading-5/normal"
            v-html="$attrs.data.body.text"
          ></div>
        </wrapper-split-text>
      </wrapper-reveal>

      <div
        ref="tpl_wrapper"
        class="w-full overflow-hidden"
        v-surfer="{
          setup: {
            destroyOn: 'visible',
          },
          observers: {
            init: true,
            visible: { event: true },
            offscreenTop: { event: true },
            offscreenBottom: { event: true },
          },
        }"
        @surfer-visible="handleSurfer('visible', false)"
        @surfer-offscreen-top="handleSurfer('offscreen-top', true)"
        @surfer-offscreen-bottom="handleSurfer(null, true)"
      >
        <!-- CAROUSEL -->
        <swiper-container
          init="false"
          class="w-full overflow-hidden"
          events-prefix="swiper-"
        >
          <swiper-slide
            v-if="$attrs.data.body.text"
            class="only-smartphone:hidden h-[40vh] supports-[height:40svh]:h-[40svh] max-h-[40vh] supports-[max-height:40svh]:max-h-[40svh] max-w-min"
          >
            <div
              class="h-full min-w-min aspect-4/5"
              data-swiper-parallax-x="50%"
              data-swiper-parallax-opacity="0"
            >
              <wrapper-split-text reveal="chars" class="w-full">
                <div
                  class="text-skin-base text-heading-5/relaxed extdesktop:text-heading-5/normal"
                  v-html="$attrs.data.body.text"
                ></div>
              </wrapper-split-text>
            </div>
          </swiper-slide>

          <template v-for="(item, index) in $attrs.data.body.items">
            <swiper-slide
              v-if="index <= maxSlidesIndex"
              class="relative top-0 left-0 overflow-hidden max-w-min"
            >
              <div
                class="flex flex-col items-stretch gap-md max-w-min"
                :class="{
                  'translate-y-[100vh]': index < 4,
                  '!translate-y-0':
                    index < 4 &&
                    (surferState === 'visible' ||
                      surferState === 'offscreen-top'),
                  'transition-transform duration-[850ms] ease-in-out':
                    index < 4 && surferState === 'visible',
                  'delay-100': index === 1 && surferState === 'visible',
                  'delay-200': index === 2 && surferState === 'visible',
                  'delay-300': index === 3 && surferState === 'visible',
                }"
              >
                <div
                  class="relative top-0 left-0 overflow-hidden"
                  :class="{
                    'min-w-[58vw] phablet:min-w-[58vw] tablet:min-w-[45vw] laptop:min-w-[33vw] desktop:min-w-[28vw] extdesktop:min-w-[26vw]':
                      item.asset.aspectRatio === '1 / 1',
                    'min-w-[62vw] phablet:min-w-[62vw] tablet:min-w-[52vw] laptop:min-w-[40vw] desktop:min-w-[35vw] extdesktop:min-w-[33vw]':
                      item.asset.aspectRatio === '3 / 2',
                    'min-w-[66vw] phablet:min-w-[66vw] tablet:min-w-[56vw] laptop:min-w-[44vw] desktop:min-w-[39vw] extdesktop:min-w-[37vw]':
                      item.asset.aspectRatio === '16 / 9',
                    'min-w-[55vw] phablet:min-w-[53vw] tablet:min-w-[42vw] laptop:min-w-[32vw] desktop:min-w-[27vw] extdesktop:min-w-[25vw]':
                      item.asset.aspectRatio === '4 / 5',
                  }"
                >
                  <wrapper-actions
                    class="w-full min-w-full overlow-hidden min-h-min h-min"
                    :class="{
                      'aspect-1/1': item.asset.aspectRatio === '1 / 1',
                      'aspect-3/2': item.asset.aspectRatio === '3 / 2',
                      'aspect-16/9': item.asset.aspectRatio === '16 / 9',
                      'aspect-4/5': item.asset.aspectRatio === '4 / 5',
                    }"
                    :asset="item.asset"
                    :use-gallery="
                      $attrs.data.body.items.length
                        ? $attrs.data.body.items
                        : undefined
                    "
                    :use-favourite="{ img_id: item.asset.wp_id }"
                    :use-moodboard="{ img_id: item.asset.wp_id }"
                  >
                    <media
                      v-bind="item.asset"
                      loading="auto"
                      :sizes="sizes[item.asset.aspectRatio]"
                      :use-bg="false"
                      class="w-full"
                    />
                  </wrapper-actions>
                </div>

                <wrapper-reveal
                  v-if="item.asset.alt && !$attrs?.config?.hideCaptions"
                  reveal="fade-from-right"
                  class="w-3/4"
                >
                  <div
                    v-html="item.asset.alt"
                    class="w-full text-body-small first-letter:uppercase text-skin-muted"
                  ></div>
                </wrapper-reveal>
              </div>
            </swiper-slide>
          </template>

          <swiper-slide
            v-if="$attrs.data.body.items.length > maxSlidesIndex"
            class="h-[40vh] supports-[height:40svh]:h-[40svh] max-h-[40vh] supports-[max-height:40svh]:max-h-[40svh] max-w-min"
          >
            <btn
              class="flex flex-col items-center justify-center h-full border border-current min-w-min aspect-4/5 laptop:aspect-1/1 gap-md text-skin-base p-lg"
              :title="`${$t.labels.view_full_gallery} (${$attrs.data.body.items.length})`"
              :use-tracking="{
                event: 'click_guarda_gallery_completa',
                gallery: $route.params.id,
              }"
              @mouseenter="
                $device.isDesktop ? tpl_hoverIcon.animate() : () => {}
              "
              @click="mediaGalleryIsOpen = true"
            >
              <hover-icon ref="tpl_hoverIcon" icon="gallery" size="huge" />
              <div
                v-html="
                  `${$t.labels.view_full_gallery} (${$attrs.data.body.items.length})`
                "
                class="text-center text-heading-6"
              ></div>
            </btn>

            <modal-media-gallery
              :is-open="mediaGalleryIsOpen"
              :items="$attrs.data.body.items"
              @close="mediaGalleryIsOpen = false"
            />
          </swiper-slide>
        </swiper-container>
        <!-- end CAROUSEL -->
      </div>

      <footer
        v-if="$attrs.data.body.items.length > 1"
        class="items-end justify-end hidden w-full pointer-events-none laptop:flex z-2 gap-lg pr-edge -mt-2xl"
      >
        <wrapper-reveal class="pointer-events-auto" reveal="fade-from-right">
          <btn-arrow
            :disabled="currentIndex === 0"
            direction="prev"
            @click="handleCarouselProgress('prev')"
          />
        </wrapper-reveal>

        <wrapper-reveal class="pointer-events-auto" reveal="fade-from-left">
          <btn-arrow
            :disabled="
              currentIndex ===
              Math.min($attrs.data.body.items.length - 1, maxSlidesIndex) -
                ($attrs.data.body.text ? 0 : 1)
            "
            direction="next"
            @click="handleCarouselProgress('next')"
          />
        </wrapper-reveal>
      </footer>
    </template>
  </block>
</template>

<script setup>
const tpl_root = templateRef("tpl_root");
const tpl_wrapper = templateRef("tpl_wrapper");
const tpl_hoverIcon = templateRef("tpl_hoverIcon");
const { width: windowW } = useWindowSize();
const maxSlidesIndex = ref(8);
const mediaGalleryIsOpen = ref(false);
const surferState = ref(null);
const currentIndex = ref(0);

let swiperEl;
const attrs = useAttrs();

const sizes = ref({
  "1 / 1": {
    base: 350,
    phablet: 600,
    tablet: 600,
    laptop: 500,
    desktop: 550,
    extdesktop: 700,
  },

  "3 / 2": {
    base: 400,
    phablet: 650,
    tablet: 700,
    laptop: 650,
    desktop: 600,
    extdesktop: 850,
  },

  "16 / 9": {
    base: 400,
    phablet: 700,
    tablet: 750,
    laptop: 700,
    desktop: 750,
    extdesktop: 950,
  },

  "4 / 5": {
    base: 350,
    phablet: 550,
    tablet: 550,
    laptop: 500,
    desktop: 550,
    extdesktop: 650,
  },
});

const offset = computed(() => {
  return Math.min(Math.max(24, (windowW.value / 100) * 4.16), 80);
});

const spaceBetween = computed(() => {
  let size;

  if (windowW.value >= 1020) {
    size = Math.min(Math.max(64, (windowW.value / 100) * 6.25), 120);
  } else size = Math.min(Math.max(24, (windowW.value / 100) * 4.16), 80);

  return size;
});

function handleCarouselProgress(nextPrev) {
  switch (nextPrev) {
    case "prev":
      swiperEl?.swiper.slidePrev();
      break;

    case "next":
      swiperEl?.swiper.slideNext();
      break;
  }
}

function handleSurfer(val, resetSlides = false) {
  surferState.value = val;
  if (resetSlides) swiperEl?.swiper?.slideTo(0, 0, false);
}

useSafeMountedEl(tpl_root, () => {
  swiperEl = tpl_root.value.$el.querySelector("swiper-container");

  Object.assign(swiperEl, {
    grabCursor: true,
    parallax: true,
    slidesPerView: "auto",
    slidesOffsetBefore:
      windowW.value >= 600
        ? offset.value * (attrs.data?.body?.text ? 1.5 : 1)
        : offset.value,
    spaceBetween: spaceBetween.value,
    slidesOffsetAfter: offset.value,
    freeMode: true,
    on: {
      init() {
        ScrollTrigger.refresh(true);
      },
      slideChange(swiper) {
        currentIndex.value = swiper.realIndex;
      },
      resize(swiper) {
        if (swiperEl && swiper) {
          swiperEl.slidesOffsetBefore =
            windowW.value >= 600
              ? offset.value * (attrs.data?.body?.text ? 1.5 : 1)
              : offset.value;
          swiperEl.slidesOffsetAfter = offset.value;
          swiperEl.spaceBetween = spaceBetween.value;
        }
      },
    },
  });

  swiperEl.initialize();
});

onBeforeUnmount(() => {
  swiperEl?.swiper?.destroy();
  swiperEl = null;
});
</script>
